<template>
  <v-footer>
    <v-container>
      <v-main>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1" class="pt-0 pb-0">
            <v-row>
              <v-col cols="12" md="2" class="text-center text-sm-left">
                <v-btn text @click="goToPress()">{{$t('press')}}</v-btn>
              </v-col>
              <v-col cols="12" md="2" class="text-center text-sm-left">
                <v-btn text @click="goToPrivacy()">{{$t('privacy')}}</v-btn>
              </v-col>
              <v-col cols="4" md="2">
                <v-icon large @click="goToFacebook()">{{icons.facebook}}</v-icon>
              </v-col>
              <v-col cols="4" md="2">
                <v-icon large @click="goToInstagram()">{{icons.instagram}}</v-icon>
              </v-col>
              <v-col cols="4" md="2">
                <v-icon large @click="goToYoutube()">{{icons.youtube}}</v-icon>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
  </v-footer>
</template>

<script>
  import {mdiFacebookBox, mdiInstagram, mdiYoutube} from '@mdi/js'
  export default {
    name: 'footer',
    data() {
      return {
        icons: {
          facebook: mdiFacebookBox,
          instagram: mdiInstagram,
          youtube: mdiYoutube,
        },
      };
    },
    methods: {
      goToPress() {
        this.$router.push({ name: 'impressum' });
      },
      goToPrivacy() {
        this.$router.push({ name: 'privacy' });
      },
      goToFacebook() {
        window.open('https://www.facebook.com/ebikeworldfederation/', '_blank');
      },
      goToInstagram() {
        window.open('https://www.instagram.com/ebikeworldfederation/', '_blank');
      },
      goToYoutube() {
        window.open('https://www.youtube.com/playlist?list=PL8jdDI7jKo3Wg_T9rl3EE5Ddh08Uu7ivE', '_blank');
      }
    }
  };
</script>

<style lang="scss">
  @import "../assets/scss/variables";
  .v-footer {
    background-color: $basic-gray;
    text-align: left;
    .v-btn {
      text-transform: none;
      font-size: 20px;
    }
    .v-icon {
      color: $primary;
    }
  }
</style>
